@import '.././_variables.scss';

.input {
  border-radius: 4px;
  background-color: $color-white;
  height: 35px;
  padding: 8px 8px;
  border: solid 1px $color-light_gray;

  &:focus {
    border: solid 1px transparent;
    border-bottom: solid 1px green;
  }
}

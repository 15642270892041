// font-sizes
$font-large: 50px;
$font-header: 30px;
$font-subheader: 20px;

// border radius
$border-radius: 5px;

// BRAND colors

$color-primary: #009900;
$color-secondary: rgb(112, 134, 241);
$color-tertiary: rgb(255, 135, 47); // active, icons, alerts (interactive features)

$color-primary-hover: rgb(2, 83, 2);
$color-secondary-hover: rgb(2, 102, 190);
$color-tertiary-hover: rgb(255, 96, 0);
$color-fourth: #00CAFF;

$color-white: #fff;
$color-black: #000;
$color-font-body: #424242;

$color-black-alpha: rgba(0, 0, 0, 0.6);

$color-white_gray: #f9f9fa;
$color-light_gray: #E5E5E5;
$color-medium_gray: #828181;
$color-dark_gray: #333;

$color-error: red;
@import '_variables.scss';
@import '../.././base/styles/_variables.scss';


.register__wrapper {
    background-image: url('https://s3-us-west-1.amazonaws.com/abstraks.com/assets/backgrounds/register_background.svg');
    height: 100%; 
    background-repeat: no-repeat;
    background-size: cover;
    background-color: $color-primary-hover;

    .container__title {
        color: $color-white;
    }
}

.register__container {
    min-height: 800px;
}

.register__content {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 40px;
    border-radius: 4px;
    margin: 0 15px 80px 15px;

    @media #{$medium} {
        float: left;
        width: 50%;
        margin: 80px 15px 80px 25%;
    }

    .form-group {
        margin: 20px auto;
    }

    .input {
        width: 100%;
        height:55px;
    }

    .form-control:focus {
        border-color: transparent;
        outline: 0;
      }

    .input:focus {
        border-bottom: 3px solid green;
    }
}

.button__register {
    background-color: $color-primary;
    width: 100%;
    padding: 20px 12px;

    &:hover {
        background-color: $color-primary-hover;
    }
}

.signUp__success--header,
.signUp__success--paragraph {
    text-align: center;
}
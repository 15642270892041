// app base
@import '_variables.scss';

@import '../.././base/styles/_variables.scss';

.logo {
  float: left;
  width: 50%;
  margin-top: 10px;

  @media #{$medium} {
    display: inline-block;
    width: 35%;
  }

  @media #{$large} {
    display: inline-block;
    width: 25%;
  }

  .anchor__navbar {
    height: 50px;
    float: left;
    margin-bottom: 10px;
    padding-top: 10px;
  }
}

.logo:after,
.logo:before {
  content: ' ';
  display: table;
}

.logo__container {
  margin-top: 5%;

  @media #{$medium} {
    margin-top: 3%;
  }

  @media #{$large} {
    width: 70%;
  }
}

.logo__container.col__container {
  padding-left: 0;
}

.logo__image {
  width: 125px;
  float: left;

  @media #{$medium} {
    float: none;
  }
}

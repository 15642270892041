@import '.././_variables.scss';
@import '../../.././base/styles/_variables.scss';

html {
  font-family: sans-serif;
}

body {
  font-family: 'Varela Round', sans-serif;
}

.container__title {
	color: $color-primary;
}

svg{
  width: 100px;
  height: 100px;
  margin: 20px;
  display:inline-block;
}
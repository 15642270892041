@import '.././_variables.scss';

.tab-list {
  padding: 15px 10px;
  margin: 0;
  font-size: 24px;
}
  
.tab-list-item {
  margin: 10px 15px;
  color: $color-white;
}

.tab-list-active {
  color: $color-white;
  border-bottom: 2px solid $color-white;
}

.tab-content {
  background-color: $color-white;
  padding: 20px 15px 45px 15px;
  border-radius: 4px;
}
@import '.././_variables.scss';

.form-control {
    border: 1px solid $color-light_gray;
    padding: 10px; 
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    display: block;
    width: 100%;
    // height: 34px;
    padding: 6px 12px;
    font-size: 16px;
    line-height: 1.42857143;
    color: $color-dark-gray;
    background-color: $color-white;
    background-image: none;
    border-radius: 4px;
  }

  .form-control:focus {
    border-color: $color-secondary;
    outline: 0;
  }
@import '_variables.scss';
@import '../.././base/styles/_variables.scss';

.logged-in {
    background-color: $color-white_gray;
}

.logged-in__user-image,
.logged-in__user-info {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;

    @media #{$medium} {
        float: left;
    }
}

.logged-in__user-image {
    float: left;
    margin: 15px 10px 15px 0;
    background-color: $color-light_gray;
    border-radius: 75px;
    height: 60px;
    width: 60px;

    .fa-user {
        display: block;
        margin: 5px 0 0 0;
        width: 30px;
        font-size: 40px;
        color: $color-white;
    }

    @media #{$medium} {
        width: 150px;
        height: 150px;

        .fa-user {
            width: 75%;
            font-size: 130px;
            margin: auto;
        }
    }
}

.logged-in__user-info {
    width: 100%;

    @media #{$medium} {
        width: 85%;
    }
}

.logged-in__container {
    margin-top: 70px;

    @media #{$medium} {
        margin-top: 95px;
    }
}

.logged-in__content {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
    float: left;
    background-color: $color-white;
}

.logged-in__content--user-info {
    width: 100%;
    margin: 40px 0;
}

.logged-in__followers,
.logged-in__following,
.logged-in__social-accounts {
    font-size: $font-subheader;
}

.logged-in__followers,
.logged-in__following,
.logged-in__social-accounts {
    width: 33.33333333%;
    float: left;
    text-align: center;
}

.logged-in__biography,
.logged-in__header-post,
.logged-in__image-post,
.logged-in__response-post,
.logged-in__comments-post {
    width: 100%;
    float: left;
}

.logged-in__header-post {
    padding: 20px 0;
    border-top: 1px solid $color-light_gray;

    .header-image,
    .logged-in__header-post--name {
        float: left;
    }

    .header-image {
        font-size: 30px;
        background-color: $color-tertiary;
        border-radius: 25px;
        border-color: $color-white_gray;
        width: 50px;
        height: 50px;
        padding: 5px 15px;

        .fa-user {
            color: $color-secondary;
        }
    }

    .header-post--name {
        margin: 10px 20px;
    }
}

.logged-in__image-icon,
.logged-in__user-panel,
.logged-in__likes-icon,
.logged-in__comments-icon {
    float: left;
}

.logged-in__likes-icon,
.logged-in__comments-icon {
    width: 50%;
    
    @media #{$medium} {
        width: 100px;
    }
}

.logged-in__image-icon,
.logged-in__likes-icon,
.logged-in__comments-icon {
    text-align: center;
}

.logged-in__image-icon {
    font-size: 200px;
    width: 100%;
    background-color: blue;
    margin-bottom: 20px;

    .fa-picture-o {
        color: lightblue;
    }
}

.logged-in__user-panel {
    width: 100%;
    margin-bottom: 20px;
}

.logged-in__likes-icon {
    .fa-heart {
        color: $color-error;
    }
}

.logged-in__comments-icon {
    .fa-comment-o {
        color: $color-secondary;
    }
}

.logged-in__content--posts {
    border-top: 1px solid $color-light_gray;
    border-bottom: 1px solid $color-light_gray;
    margin-bottom: 50px;

    @media #{$medium} {
        border: 1px solid $color-light_gray;
    }
}

.comment-group {
    border-top: 1px solid $color-white_gray;

    .user-name,
    .user-comment {
        display: inline-block;
    }

    .user-name {
        margin-right: 5px;
        margin-top: 15px;
        margin-bottom: 0;

        @media #{$medium} {
            margin-bottom: 15px;
        }
    }
}